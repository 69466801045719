import React from 'react'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'
import FestifHelmet from '../../components/Helmet/FestifHelmet'

const Appmobile = () => {
  const metaTitle = 'Application mobile | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Alertes « spectacles surprises », carte interactive, programmation et plus encore: téléchargez l’indispensable app mobile du Festif!'
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, application mobile, app, app store, google play'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader title="App mobile" />
      <div className="container content pb-12">
        <div>

          {/* DOWNLOAD BUTTONS */}
          <div className="flex md:pl-[60px] px-[10px] my-10">
            <a href="https://apps.apple.com/ca/app/le-festif/id895648726" target="_blank" rel="noreferrer">
              <StaticImage className={'max-w-[250px] mr-2'}
                            src={'../../images/stores/appstore-logo.png'}
                            placeholder="blurred"
                            objectFit="contain"
                            alt="Logo App Store"/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=ca.lefestif&hl=fr_CA&gl=US" target="_blank" rel="noreferrer">
              <StaticImage className={'max-w-[250px] ml-2'}
                            src={'../../images/stores/googleplay-logo.png'}
                            placeholder="blurred"
                            objectFit="contain"
                            alt="Logo Google Play Store" />
            </a>
          </div>

          {/* HIGHLIGHTED TEXT */}
          <div>
            <h5>Pour demeurer à l’affût des nouvelles et des moments magiques qui, au Festif!, peuvent surgir à tout instant, télécharge notre application dès maintenant !</h5>
            <br/><br/>
          </div>

          {/* MAIN TEXT */}
          <div>
              <p>Absolument indispensable, l’application du Festif! te facilitera la vie tout au long du festival. Tu y trouveras la plus récente programmation (adaptable en fonction de tes intérêts), des informations sur les artistes, des détails sur les divers événements du week-end, une carte interactive de Baie-Saint-Paul (présentant sites de spectacles, points d’eau, stationnements, restaurants, etc.) !</p>
              <br/><br/>
          </div>

          {/* CREDITS */}
          <div className="mt-5">
            <p className="flex">L'application mobile est une présentation de <a href="https://bell.ca/fr" className="no-underline"><StaticImage src={'../../images/partenaires/bell.png'} className="ml-2 h-[20px] w-[30px]" objectFit='contain'/></a></p>
            <br/>
            <p>Conception de l'application : <a className="!no-underline hover:!underline" href="mailto:ntremblaymobile@gmail.com"><b>Nicolas Tremblay</b></a></p>
          </div>
        </div>
      </div>
    </Wolflayout>
  )
}

export default Appmobile
